<template>
    <div>
        <NavOnlineAdmission></NavOnlineAdmission>
        <b-container>
            <b-row class="mt-5 justify-content-center">
                <SideNavOnlineAdmission></SideNavOnlineAdmission>
                <b-col cols="12" md="9" class="">
                    <b-card>
                        <b-card-body>
                            <b-row>

                                <div class="col-md-6">
                                    <b-form-group label-for="father_nid">
                                        <template v-slot="label">
                                            <label>Father NID <span class="text-danger">*</span></label>
                                            <b-form-file v-model="father_nid" placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..." @change="onFatherNidFileChange"
                                                accept="image/*,application/pdf"></b-form-file>
                                            <b-form-invalid-feedback :state="fatherNidState">
                                                <span v-if="errors.father_nid">{{ errors.father_nid[0]
                                                }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-6">
                                    <b-form-group label-for="mother_nid">
                                        <template v-slot="label">
                                            <label>Mother NID <span class="text-danger">*</span></label>
                                            <b-form-file v-model="mother_nid" placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..." @change="onMotherNidFileChange"
                                                accept="image/*,application/pdf">
                                                <!-- Change 'father_nid' to 'mother_nid' -->
                                            </b-form-file>
                                            <b-form-invalid-feedback :state="motherNidState">
                                                <span v-if="errors.mother_nid">{{ errors.mother_nid[0] }}</span>
                                            </b-form-invalid-feedback>
                                        </template>
                                    </b-form-group>
                                </div>

                                <div class="col-md-12">
                                

                                    <b-button variant="primary" @click="gotoPayment" data-toggle="tooltip"
                                        data-placement="top" title="Click here to save your file">Next</b-button>
                                </div>
                            </b-row>
                        </b-card-body>
                    </b-card>


                    <b-card v-if="AplicantFiles && AplicantFiles.length > 0" style="background-color: #F8F0E5;">
                        <b-card-body>
                            <b-row>
                                <!-- <div v-for="(file, index) in AplicantFiles" :key="index" class="col-md-4">
                                    <b-card :title="file.file_name" :img-src="file.file_path" img-alt="Image" img-top
                                        tag="article" style="max-width: 20rem;" class="mb-2">
                                      
                                    </b-card>
                                </div> -->
                                <div v-for="(file, index) in AplicantFiles" :key="index" class="col-md-4">
                                    <b-card :title="file.file_name" img-top tag="article" class="mb-2 m-0">
                                        <template v-if="file.file_extension === 'pdf'">
                                            <!-- Display a link for PDF files -->
                                            <!-- <a :href="file.file_path" target="_blank" class="btn btn-info">Click here to
                                                view PDF</a> -->
                                            <a :href="file.file_path"  id="pdfButton"  target="_blank" class="btn btn-info"
                                                style="position: absolute; top: 10px; right: 20px;">View</a>
                                            <object :data="file.file_path" type="application/pdf" width="100%"
                                                height="100%">
                                                Your browser does not support PDFs.
                                                <a :href="file.file_path" target="_blank" class="btn btn-info mt-2">Click
                                                    here to
                                                    view PDF</a>
                                            </object>
                                        </template>
                                        <template v-else>
                                            <!-- Display an image for non-PDF files -->
                                            <a :href="file.file_path" target="_blank">
                                                <img :src="file.file_path" :alt="file.file_name" style="max-width: 100%;" />
                                                <button class="btn btn-info"
                                                    style="position: absolute; top: 10px; right: 20px;">View</button>
                                            </a>
                                        </template>
                                    </b-card>
                                </div>
                            </b-row>
                        </b-card-body>
                    </b-card>


                </b-col>

            </b-row>
        </b-container>
    </div>
</template>
  
<script>
import {
    BContainer, BCol, BListGroup, BListGroupItem, BCard, BRow, BFormFile, BCardBody, BFormGroup, BButton, BFormInvalidFeedback
} from 'bootstrap-vue'

import NavOnlineAdmission from '@/views/components/nav/NavOnlineAdmission.vue';
import SideNavOnlineAdmission from '@/views/components/nav/SideNavOnlineAdmission.vue';
import axios from 'axios';

export default {
    components: {
        BContainer,
        BCol,
        BListGroup,
        BListGroupItem,
        BCard,
        BRow,
        BFormFile,
        BCardBody,
        BFormGroup,
        BButton,
        NavOnlineAdmission,
        SideNavOnlineAdmission,
        BFormInvalidFeedback

    },
    data() {
        return {
            fatherNidState: null,
            motherNidState: null,
            file1: null,
            father_nid: null,
            mother_nid: null,
            institute: this.$route.params.institute,
            formId: this.$route.params.formId,
            temporaryId: this.$route.params.temporaryId,
            AplicantFiles: [],
            FormData: {
                father_nid: [],
                mother_nid: [],
                institution_id: null,
                online_adm_applicant_id: null,
                temporaryId: this.$route.params.temporaryId,

            },
            errors: [],
        }
    },


    created() {
        this.getapplicant();

    },

    methods: {
        gotoPayment() {
            let institute = this.$route.params.institute;
            this.$router.push({ path: `/admission/payment/${institute}/online-payment` });
        },

        getapplicant() {
            axios.get(process.env.VUE_APP_API_URL + `get-applicant/?tempId=` + this.temporaryId)
                .then(response => {
                    this.$set(this.FormData, 'institution_id', response.data.data.institution_id);
                    this.$set(this.FormData, 'online_adm_applicant_id', response.data.data.id);
                    localStorage.setItem('applicantId', response.data.data.id);
                    this.getAplicantFile(response.data.data.id);
                })
                .catch(error => {
                    // console.log(error);
                });
        },

        getAplicantFile(id) {

            axios.get(process.env.VUE_APP_API_URL + `get-online-form-submit-attachment?online_adm_applicant_id=` + id)
                .then(response => {
                    this.AplicantFiles = response.data.data;
                })
                .catch(error => {
                    // console.log(error);
                });
        },


        // onFileChange(e) {
        //     console.log(e.target.files[0]);
        //     this.fatherNid = e.target.files[0];
        //     this.motherNid = e.target.files[0];
        // },

        onFatherNidFileChange(e) {
            console.log('Father NID file:', e.target.files[0]);
            this.fatherNid = e.target.files[0];
            this.submitForm('draft')
        },


        onMotherNidFileChange(e) {
            console.log('Mother NID file:', e.target.files[0]);
            this.motherNid = e.target.files[0];
            this.submitForm('draft')
        },

        // onFileChange(type) {
        //     const fileInput = event.target.files[0];
        //     // const files = fileInput.files;
        //     const reader = new FileReader();

        //     if (type === 'father_nid') {

        //         reader.onload = e => {
        //             this.FormData.father_nid = e.target.result
        //         }
        //         console.log(fileInput)
        //         reader.readAsDataURL(fileInput)
        //     } else if (type === 'mother_nid') {
        //         reader.onload = e => {
        //             this.FormData.mother_nid = e.target.result
        //         }
        //         console.log(fileInput)
        //         reader.readAsDataURL(fileInput)

        //         // this.FormData.mother_nid = Array.from(files);
        //     }



        // },


        submitForm(status) {
            const formData = new FormData();

            // Append files to FormData
            // if (this.FormData.father_nid) {
            //     formData.append('father_nid', this.FormData.father_nid);
            // }

            // if (this.FormData.mother_nid) {
            //     formData.append('mother_nid', this.FormData.mother_nid);
            // }

            // if (this.FormData.online_adm_applicant_id) {
            //     formData.append('online_adm_applicant_id', this.FormData.online_adm_applicant_id);
            // }

            // if (this.FormData.institution_id) {
            //     formData.append('institution_id', this.FormData.institution_id);
            // }

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            if (this.fatherNid !== undefined) {
                formData.append('father_nid', this.fatherNid);
            }

            if (this.motherNid !== undefined) {
                formData.append('mother_nid', this.motherNid);
            }
            formData.append('institution_id', this.FormData.online_adm_applicant_id);
            formData.append('online_adm_applicant_id', this.FormData.online_adm_applicant_id);



            axios.post(process.env.VUE_APP_API_URL + 'online-form-submit-attachment', formData, config)
                .then((response) => {
                    this.getAplicantFile(response.data.data.online_adm_applicant_id);
                    // this.$router.push({ path: `/admission/payment/${institute}/online-payment` });
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;

                    const fieldStateMap = {
                        father_nid: 'fatherNidState',
                        mother_nid: 'motherNidState'
                    };

                    for (const field in fieldStateMap) {
                        if (this.errors[field]) {
                            this[fieldStateMap[field]] = false;
                        }
                    }
                });
        },

        // submitForm(status) {
        //     const formData = new FormData();

        //     // Append files to FormData
        //     if (this.FormData.father_nid) {
        //         formData.append('father_nid', this.FormData.father_nid);
        //     }

        //     if (this.FormData.mother_nid) {
        //         formData.append('mother_nid', this.FormData.mother_nid);
        //     }

        //     if (this.FormData.online_adm_applicant_id) {
        //         formData.append('online_adm_applicant_id', this.FormData.online_adm_applicant_id);
        //     }

        //     if (this.FormData.institution_id) {
        //         formData.append('institution_id', this.FormData.institution_id);
        //     }



        //     console.log("my form data" + this.FormData);
        //     let institute = this.$route.params.institute;
        //     axios.post(process.env.VUE_APP_API_URL + 'online-form-submit-attachment', formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     }).then(response => {
        //         // this.formData = response.data.data;
        //         this.$router.push({ path: `/admission/payment/${institute}/online-payment`, });
        //         console.log('POST request success:', response.data);
        //         // this.districts = response.data.data.districts;
        //         // this.thanas = response.data.data.AllThana;
        //         // this.postCodes = response.data.data.postCodes;
        //     })
        //         .catch(error => {
        //             // console.log('POST request success:', error);
        //         });

        // }
    },

}
</script>
<style scoped>
.step {
    border: none;
    text-align: left;
}

a {
    color: white;
}

@media screen and (max-width: 600px) {
  #pdfButton {
    display: none;
  }
}
</style>
  